// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/ui/atoms/Alert.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/ui/atoms/Alert.tsx");
  import.meta.hot.lastModified = "1722523621455.827";
}
// REMIX HMR END

import { cva } from 'class-variance-authority';
import * as React from 'react';
import { createContext, forwardRef, useContext } from 'react';
import { invariant } from '@/utils';
import { AlertCircleFilled } from '~/ui/icons/alert-icons/AlertCircleFilled';
import { AlertCircleOutline } from '~/ui/icons/alert-icons/AlertCircleOutline';
import { AlertTriangleFilled } from '~/ui/icons/alert-icons/AlertTriangleFilled';
import { AlertTriangleOutline } from '~/ui/icons/alert-icons/AlertTriangleOutline';
import { cn } from '~/utils/cn';
import { Button } from './Button';
const alertVariantsConfig = {
  variants: {
    variant: {
      success: 'bg-green-50 border-green-200 stroke-green-500',
      info: 'bg-blue-50 border-blue-200 stroke-blue-500',
      warning: 'bg-warning text-primary border-amber-300',
      error: 'text-red-500 border-red-200 bg-red-50'
    }
  },
  defaultVariants: {
    variant: 'info'
  }
};
const alertVariants = cva('w-full rounded border p-4 text-sm text-secondary items-start flex flex-row gap-3', alertVariantsConfig);
const AlertVariantContext = createContext(alertVariantsConfig.defaultVariants.variant);
const AlertMain = forwardRef(_c = ({
  className,
  variant = alertVariantsConfig.defaultVariants.variant,
  ...props
}, ref) => {
  return <AlertVariantContext.Provider value={variant}>
        <div ref={ref} role='alert' className={cn(alertVariants({
      variant
    }), className)} {...props} />
      </AlertVariantContext.Provider>;
});
_c2 = AlertMain;
AlertMain.displayName = 'Alert';
const AlertBody = forwardRef(_c3 = ({
  className,
  children,
  ...props
}, ref) => {
  return <div ref={ref} className={cn('flex w-0 grow flex-col gap-2', className)} {...props}>
      {children}
    </div>;
});
_c4 = AlertBody;
AlertBody.displayName = 'AlertBody';
const AlertIcon = _s(forwardRef(_c5 = _s(({
  className,
  shape: shapeProp,
  isFilled = false,
  ...props
}, ref) => {
  _s();
  const variant = useContext(AlertVariantContext);
  invariant(variant, 'Alert.Icon must be used within an Alert component');
  const shape = shapeProp ?? (variant === 'info' || variant === 'success') ? 'circle' : 'triangle';
  const iconProps = {
    variant: variant ?? undefined,
    className: cn('alert-icon shrink-0 row-span-2', className)
  };
  const CircleIcon = isFilled ? AlertCircleFilled : AlertCircleOutline;
  const TriangleIcon = isFilled ? AlertTriangleFilled : AlertTriangleOutline;
  return shape === 'circle' ? <CircleIcon ref={ref} {...iconProps} {...props} /> : <TriangleIcon ref={ref} {...iconProps} {...props} />;
}, "uX4uEUursEurzyU8csTRsU2jOoI=")), "uX4uEUursEurzyU8csTRsU2jOoI=");
_c6 = AlertIcon;
AlertIcon.displayName = 'AlertIcon';
const AlertTitle = forwardRef(_c7 = ({
  className,
  children,
  ...props
}, ref) => {
  return <h5 ref={ref} className={cn('alert-title font-medium text-inherit', className)} {...props}>
      {children}
    </h5>;
});
_c8 = AlertTitle;
AlertTitle.displayName = 'AlertTitle';
const AlertDescription = forwardRef(_c9 = ({
  className,
  ...props
}, ref) => {
  return <div ref={ref} className={cn('alert-description break-words text-inherit', className)} {...props} />;
});
_c10 = AlertDescription;
AlertDescription.displayName = 'AlertDescription';
const ActionButton = forwardRef(_c11 = ({
  children,
  ...rest
}, ref) => {
  return <Button ref={ref} size='sm' className='bg-white' variant='outline' {...rest}>
      {children}
    </Button>;
});
_c12 = ActionButton;
export const Alert = Object.assign(AlertMain, {
  Body: AlertBody,
  Title: AlertTitle,
  Description: AlertDescription,
  Icon: AlertIcon,
  ActionButton
});
_c13 = Alert;
var _c, _c2, _c3, _c4, _c5, _c6, _c7, _c8, _c9, _c10, _c11, _c12, _c13;
$RefreshReg$(_c, "AlertMain$forwardRef");
$RefreshReg$(_c2, "AlertMain");
$RefreshReg$(_c3, "AlertBody$forwardRef");
$RefreshReg$(_c4, "AlertBody");
$RefreshReg$(_c5, "AlertIcon$forwardRef");
$RefreshReg$(_c6, "AlertIcon");
$RefreshReg$(_c7, "AlertTitle$forwardRef");
$RefreshReg$(_c8, "AlertTitle");
$RefreshReg$(_c9, "AlertDescription$forwardRef");
$RefreshReg$(_c10, "AlertDescription");
$RefreshReg$(_c11, "ActionButton$forwardRef");
$RefreshReg$(_c12, "ActionButton");
$RefreshReg$(_c13, "Alert");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;