// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/ui/molecules/Banner.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/ui/molecules/Banner.tsx");
  import.meta.hot.lastModified = "1720568675547.3489";
}
// REMIX HMR END

import { forwardRef } from 'react';
import { unknownErrorContent, unknownToErrorContent } from '@/utils';
import { cn } from '~/utils/cn';
import { Alert } from '../atoms/Alert';
const BannerMain = forwardRef(_c = ({
  title,
  actionButton,
  cancelButton,
  className,
  children,
  icon: iconProp,
  ...rest
}, ref) => {
  const icon = iconProp ?? <Alert.Icon className={cn(children != null && 'self-start')} />;
  return <Alert className={cn('items-center', className)} {...rest} ref={ref}>
        {icon}
        {(title != null || children != null) && <Alert.Body>
            {title != null && <Alert.Title>{title}</Alert.Title>}
            {children != null && <Alert.Description>{children}</Alert.Description>}
          </Alert.Body>}

        {(cancelButton || actionButton) && <div className={cn('flex items-center gap-2', (icon != null || title != null || children != null) && 'ml-auto')}>
            {cancelButton != null && cancelButton}
            {actionButton != null && actionButton}
          </div>}
      </Alert>;
});
_c2 = BannerMain;
BannerMain.displayName = 'Banner';
function ErrorContentBanner({
  error,
  title: titleProp,
  ...rest
}) {
  if (error == null) return null;
  const errorContent = typeof error === 'string' ? {
    ...unknownErrorContent,
    detail: error
  } : unknownToErrorContent(error);
  const title = titleProp ?? errorContent.detail;
  const description = errorContent.errors.length > 0 ? errorContent.errors.map(e => e.description).join('\n') : null;
  return <BannerMain variant='error' title={title} {...rest}>
      {description}
    </BannerMain>;
}
_c3 = ErrorContentBanner;
export const Banner = Object.assign(BannerMain, {
  ErrorContent: ErrorContentBanner,
  ActionButton: Alert.ActionButton
});
_c4 = Banner;
var _c, _c2, _c3, _c4;
$RefreshReg$(_c, "BannerMain$forwardRef");
$RefreshReg$(_c2, "BannerMain");
$RefreshReg$(_c3, "ErrorContentBanner");
$RefreshReg$(_c4, "Banner");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;